// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "软征科技",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <GitHubIcon />,
      link: "https://github.com",
    },
  ],
  menus: [
    {
      name: "公司",
      items: [{ name: "登录", route: "/pages/authentication/sign-in" }],
    },
    {
      name: "导航",
      items: [],
    },
    {
      name: "产品中心",
      items: [],
    },
    {
      name: "帮助与支持",
      items: [
        { name: "关于我们", route: "/pages/landing-pages/about-us" },
        { name: "联系我们", route: "/pages/landing-pages/contact-us" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href=""
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Ruanzheng
      </MKTypography>
      .
      <br />
      <MKTypography
        component="a"
        href="https://beian.miit.gov.cn/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        粤ICP备2020129470号-3
      </MKTypography>
    </MKTypography>
  ),
};
