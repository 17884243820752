/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default [
  {
    image: "assets/images/pages/machine-arm.jpg",
    name: "机械臂",
    route: "/",
  },
  {
    image: "assets/images/pages/machine-hand.jpg",
    name: "机械手",
    route: "/",
  },
  {
    image: "assets/images/pages/machine-lamp.jpg",
    name: "机械灯",
    route: "/",
  },
  {
    image: "assets/images/pages/sound-recording.jpg",
    name: "录音",
    route: "/",
  },
];
